@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a, a:hover, a:visited {
    color: inherit;
    text-decoration: none;
}

html {
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    font-size: 12px;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.webgl {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

.app {
    width: 100vw;
    min-height: 100vh;
}

.section {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-top: 20%;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    font-family: 'Cabin', sans-serif;
    color: #ffeded;
    margin: 0 auto;
    gap: 2rem;
    pointer-events: none;
    width: 80%;
    border-top: 1px solid white;
}

.section * {
    pointer-events: auto;
}

#landing.section {
    padding-top: 0;
    justify-content: center;
    border-top: 0;
}

.section .content, .section .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.section .content-wrapper {
    gap: 2rem;
}

.section h1, .section h2, .section h3, .section h4, .section h5, .section h6
{
    text-transform: uppercase;
}

.canvasfree {
    height: 40%;
    width: 100%;
    pointer-events: none;
}

.logo {
    width: 70%;
    margin-bottom: 1rem;
    text-align: center;
}

.projects-control button {
    position: absolute;
    font-size: 2.5rem;
    top: 40%;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
}

.projects-control button.left {
    left: -2rem;
}

.projects-control button.right {
    right: -2rem;
}

.project {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    min-width: 0;
    width: 100%;
    flex-shrink: 0;
    opacity: 0;
    transition: opacity 0.5s linear;
}

.project.show {
    opacity: 1;
}

.scrolldown {
    position: absolute;
    bottom: 15%;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: fit-content;
}

.scrolldown i {
    font-size: 2rem;
}

.linkList {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    margin-top: 0.2rem;
}

a.colored {
    color: #65C9FF;
}

a.link {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: #65C9FF;
}

a.link:hover {
    font-weight: 600;
}

a.icon {
    font-size: 1.7rem;
}

.txtc {
    text-align: center;
}

.hobbies {
    margin-top: 1.5rem;
}

.hobby {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.hobby img {
    width: 3.2rem;
    height: 3.2rem;
}

.hobby .title {
    font-weight: 600;
}

.laptop-content {
    width: 334px;
    height: 216px;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    overflow-y: auto;
    padding: 0;
}

.laptop-content.endlesswander {
    background-image: url('./assets/img/endlesswander.webp');
}

.laptop-content.ederiawebsite {
    background-image: url('./assets/img/ederiawebsite.webp');
}

.laptop-content.clicknboat {
    background-position: 0;
    background-image: url('./assets/img/clicknboat.webp');
}

.laptop-content.myblazon {
    background-image: url('./assets/img/myblazon.webp');
}

.laptop-content.flappy {
    background-image: url('./assets/img/flappy.webp');
}

.laptop-content.eportfolio {
    background-image: url('./assets/img/eportfolio.webp');
}

.phone-content {
    width: 1150px;
    height: 2100px;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    overflow-y: auto;
    padding: 0;
}

.phone-content.huun {
    background-image: url('./assets/img/huun.webp');
}

.copyright {
    position: absolute;
    bottom: 5%;
    font-size: 0.9rem;
    text-align: center;
}

.copyright .credits {
    margin-top: 0.3rem;
    font-size: 0.8rem;
}

.loading-screen {
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Cabin', sans-serif;
    color: #ffeded;
    gap: 2rem;
}

.loading-screen .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    height: 5rem;
}

nav {
    position: fixed;
    top: 2vh;
    left: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    font-family: 'Cabin', sans-serif;
    color: #ffeded;
    font-size: 1.5rem;
}

.langButton {
    position: fixed;
    top: 2vh;
    right: 2vw;
    z-index: 1;
    width: 2rem;
    height: 2rem;
}

.langButton button {
    background: none;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

@media (min-width: 800px) {
    .section {
        width: 60%;
        padding-top: 10%;
        scroll-snap-align: start;
        border-top: 0;
    }

    .section h2 {
        margin-bottom: 3rem;
    }

    html {
        font-size: 14px;
    }

    .loading-screen {
        width: 100vw;
        padding: 0 20vw;
    }

    nav {
        top: 20%;
        justify-content: center;
        bottom: 20%;
    }

    .credits {
        width: 80%;
        margin: 0 auto;
    }

    .langButton {
        width: 3rem;
        height: 3rem;
    }
}

@media (min-width: 1200px) {
    .section {
        width: 40%;
        padding-top: 5%;
    }

    .canvasfree {
        height: 40%;
    }

    .loading-screen {
        width: 100vw;
        padding: 0 30vw;
    }

    .credits {
        width: 70%;
    }

    .langButton {
        width: 4rem;
        height: 4rem;
    }
}